import React from "react"
import Layout from "../templates/pageLayout"

import * as reviewsStyles from "./reviews.module.css"

import jase_avatar from "../images/reviews/jase_avatar.png"
import jack_avatar from "../images/reviews/jack_avatar.png"
import david_avatar from "../images/reviews/david_avatar.png"
import { Icon } from "@iconify/react"
import starIcon from "@iconify/icons-emojione-monotone/star"
import userAvatarFilled from "@iconify/icons-carbon/user-avatar-filled"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function Reviews() {
  return (
    <Layout title="Our Reviews">
      <div className="container">
        <div className={reviewsStyles.reviewsContainer}>
          <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
            <h1>OUR REVIEWS</h1>
            <p>
              We let our work speak for itself, but we are also proud to receive
              consistently positive reviews from a long list of clients. We have{" "}
              <span style={{ color: "#FF6633" }}>hundreds</span> of independent
              reviews left by satisfied customers on Google and across our
              social media. It is important to note the volume and consistency
              of those reviews, which go back many years.
            </p>
          </ScrollAnimation>
          <div id={reviewsStyles.reviewsGrid}>
            {reviewsList.map((review, index) => {
              return <ReviewCard review={review} key={index} />
            })}
          </div>
          <span style={{ textAlign: "center", marginTop: "30px" }}>
            For a full list of our reviews visit
          </span>
          <div className={reviewsStyles.socialGrid}>
            <div className={reviewsStyles.socialContainer}>
              <a
                href="https://www.bark.com/en/gb/company/tintex--wrapping-ltd/qlOl/"
                className={reviewsStyles.socialTitle}
              >
                Bark
              </a>
              <br />
              <p className={reviewsStyles.socialText}>(700+ reviews)</p>
            </div>
            <div className={reviewsStyles.socialContainer}>
              <a
                href="https://g.page/tintexportsmouth?share"
                className={reviewsStyles.socialTitle}
              >
                Google
              </a>
              <br />
              <p className={reviewsStyles.socialText}>(570+ reviews)</p>
            </div>
            <div className={reviewsStyles.socialContainer}>
              <a
                href="https://www.facebook.com/tintexportsmouth/reviews"
                className={reviewsStyles.socialTitle}
              >
                Facebook
              </a>
              <br />
              <p className={reviewsStyles.socialText}>(300+ reviews)</p>
            </div>
          </div>
        </div>
        <div className={reviewsStyles.partnersContainer}>
          <h2>OUR TRUSTED PARTNERS</h2>
          <p>
            Over the years we didn’t just build up trust from the people but
            local businesses too, which we can proudly call our trusted
            partners. Our partners include:
          </p>
          <ul id="featureList">
            <li>Local high end dealerships</li>
            <li>Local vehicle dealers</li>
            <li>Local councils</li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

class ReviewCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      img: [],
    }
  }

  render() {
    const { review } = this.props
    return (
      <div className={reviewsStyles.reviewCard}>
        <div className={reviewsStyles.reviewsTopContainer}>
          {review.img ? (
            <img
              className={reviewsStyles.reviewImg}
              alt=""
              src={review.img}
              draggable={false}
            ></img>
          ) : (
            <Icon icon={userAvatarFilled} className={reviewsStyles.reviewSvg} />
          )}
          <div className={reviewsStyles.reviewsNameContainer}>
            <span className={reviewsStyles.reviewName}>{review.name}</span>
            <div className={reviewsStyles.reviewStars} alt="5 stars">
              <Icon
                className={reviewsStyles.reviewStar}
                icon={starIcon}
                alt="One review star"
              ></Icon>
              <Icon
                className={reviewsStyles.reviewStar}
                icon={starIcon}
                alt="One review star"
              ></Icon>
              <Icon
                className={reviewsStyles.reviewStar}
                icon={starIcon}
                alt="One review star"
              ></Icon>
              <Icon
                className={reviewsStyles.reviewStar}
                icon={starIcon}
                alt="One review star"
              ></Icon>
              <Icon
                className={reviewsStyles.reviewStar}
                icon={starIcon}
                alt="One review star"
              ></Icon>
            </div>
            <span className={reviewsStyles.reviewDate}>{review.date}</span>
          </div>
        </div>
        <p className={reviewsStyles.reviewText}>{review.text}</p>
      </div>
    )
  }
}

const reviewsList = [
  {
    img: jase_avatar,
    name: "Jase Lewis",
    date: "September 2020",
    text:
      "Our T5.1 needed a lot of paint correction but the results that Tintex gave us were well above and beyond what we had expected. The ceramic coating was just the icing on the cake and our van now looks totally stunning. So much so that it won it's first trophy at our VW club meet. Would 100% recommend Tintex to anyone who wants the wow factor back on their pride and joy.",
  },
  {
    img: "",
    name: "Sonia Banks",
    date: "August 2020",
    text:
      "I left my very grubby car to be valeted and was absolutely thrilled with the result.  I have cream fabric seats and a dog - there wasn't a dog hair to be seen when I collected my Skoda Roomster.  The car was gleaming on the outside too so looks just like new! It was also a pleasure to be able to deal with the proprietor who was very knowledgeable  and commented that he thought the engine was running rather fast and perhaps should be checked out.  My knowledge of engines is negligible - I just drive the car!",
  },
  {
    img: "",
    name: "Glen Minto",
    date: "August 2020",
    text: `Booked in to have my rear lights tinted yesterday, job done this morning! Very high quality work, professional as can be and the owner is very knowledgeable. Pricing was as expected with this quality of work and I would highly recommend and come back for additional work! Overall fantastic experience, Glen.`,
  },
  {
    img: "",
    name: "Len Rogers",
    date: "August 2020",
    text:
      "Just had my headlight lenses polished. Absolutely brilliant job, better than new if that is possible.  So glad I didn't mess about trying to do it myself when quality workmanship like this is available at a very reasonable price. Thank you.",
  },
  {
    img: jack_avatar,
    name: "Jack Dancy",
    date: "July 2020",
    text:
      "Would highly recommend, from booking to dropping my car off, to the end result, I got nothing but top professionalism and the car looks 100x better with the dark tint on all rear windows and is much cooler in the back. Wont hesitate to go back with any future car, for tinting, wrapping etc.",
  },
  {
    img: "",
    name: "Caroline Evans",
    date: "July 2020",
    text:
      "Bought my car earlier this year but previous owner hadn't really looked after it quite a few scratches here and there which i wasn't happy with,have just picked my car up from these guys my car looks brand new totally love my car now thank you so much for your friendly and efficient service ",
  },
  {
    img: david_avatar,
    name: "David Mihai S",
    date: "June 2020",
    text:
      "Michael and his team did a great job on tinting the back and front of my car. I chose them because of a friend recommendation, the reviews written by other people and because they are the closest LLumar dealer (best window film tint in my onion) to Southampton. I'll strongly recommend them to my family and friend and will come back in the future for bits. Thank you!",
  },
  {
    img: "",
    name: "John O'Rourke",
    date: "April 2020",
    text:
      "Prior to buying a new car, I visited Tintex where Michael explained the various options with regards to car paint protection. I decided to have the car front wrapped in clear PPF, the remainder ceramic coated and the rear windows tinted. The work was carried out to a very high standard and the car looks amazing. My thanks to Michael and his team.",
  },
]
